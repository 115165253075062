import {useContext, useMemo} from "react";
import {StartContext, StartInterface} from "../view/stake_list";
import {useGetCurrency} from "../state/CurrentCoin/hooks";


export const useStakeBalance = () => {
    const {stakeBalance, balanceActivity} = useContext(StartContext);
    const {currency} = useGetCurrency();

    return useMemo<any>(() => {
        return {...stakeBalance[currency.name.toLowerCase()], balanceActivity} ?? {
            leverage: "", reward: "", staked: "", apy: "", balance: "",
            locked: "",
            withdraw: "",
            powerLeft: "",
            pending: "",
            balanceActivity: "0",
        }
    }, [currency, stakeBalance]);
}
