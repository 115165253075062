import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "../index";
import {useCallback, useEffect, useState} from "react";
import {upDataCurrency} from "./actions";
import {CurrencyState} from "./reducer";


export const useUpDataCurrency = () => {
    const dispatch = useDispatch<AppDispatch>();
    return useCallback(async (ev: CurrencyState) => {

        dispatch(upDataCurrency({...ev}))
    }, [],);
}



export const useGetCurrency = () => {
    return useSelector((state: AppState) => state.CurrentCoin);
}


