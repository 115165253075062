import React, {useContext, useMemo} from "react";
import {useModel} from "../../../hook/use_model";
import {ButtonWidget, ImageWidget, ValueWidget} from "../../base";
import {CardCellWidget, ChooseCardStyle} from "../choose/choose_card";
import styled from "styled-components";
import {StartStyle} from "../choose/start_style";
import {CradCentext} from "../../../view/start_mining/choose_page";
import {useCardFn} from "../../../view/start_mining/fn/card_fn";
import {getBalanceFormat, getBalanceNumber} from "../../../utils/formatBalance";
import {SendModal} from "../../modal/send_modal";
import {PidMap, useClaim} from "../../../server/capita_stake_server";
import {StartMiningContext} from "../../../view/start_mining";
import {ChooseImgWidget} from "../widget/choose_img";
import {goToHref} from "../../base/net_select";
import {SwitchNetButton} from "../widget/switch_net";
import {useGetCurrency} from "../../../state/CurrentCoin/hooks";
import {SwitchCurrencyButton} from "../widget/switch_currency";


export const DashboardCard: React.FC = () => {
    const model = useContext(CradCentext)
    const {amount, deployed, pendingNsure} = useCardFn(model);
    let {mcr:allMcr} = useContext(StartMiningContext);

    const handleClaim = useClaim(model.nick);
    const {currency} = useGetCurrency()

    const [onClaim] = useModel(
        <SendModal confirmationFn={handleClaim}/>
    );


    const isAc = useMemo(() => {
        if (model.link) return false;
        if (PidMap[model.nick.toLowerCase()] !== currency.id) return false;
        return true
    }, [model, currency])

    if (!allMcr) return <span/>
    let {mcrUsdc, mcr} = allMcr;
    return (
        <ChooseCardStyle size={{all: 30}} className={isAc ? "ac" : "bg"}>
            <ChooseImgWidget img={model.url}/>

            {/*<ImageWidget bgColor={model.bgColor} borderColor={model.bgColor} size={70} url={model.url}/>*/}
            <h3>n{model.nick.toUpperCase()} Pool</h3>

            <CardCellWidget label={`Solvency Ratio (Capital/MCR):`}>
                <ValueWidget
                    value={((model.changeContract && mcr) ? (model.nick.toLowerCase() == "matic" ? mcr : mcrUsdc) : 0) + "%"}
                    decimals={3}/>
                {/*<ValueWidget value={"NA"} decimals={3}/>*/}
            </CardCellWidget>
            <CardCellWidget label={`Pool n${model.nick.toUpperCase()} deployed:`}>
                <ValueWidget value={model.link ? "N/A" : getBalanceFormat(deployed)} decimals={3}/>
            </CardCellWidget>

            <CardCellWidget label={`My n${model.nick.toUpperCase()}:`}>
                <ValueWidget value={model.link ? "N/A" : getBalanceFormat(amount)} decimals={3}/>
            </CardCellWidget>
            <CardCellWidget
                label={model.rewardUnit ? `Available Reward (${model.rewardUnit}):` : 'Available Reward'}>
                <ValueWidget value={model.link ? "N/A" : getBalanceFormat(pendingNsure || '')} decimals={3}/>
            </CardCellWidget>

            <ButtonStyle>
                {
                    model.link ?
                        <SwitchNetButton/> :
                        <SwitchCurrencyButton name={model.nick}>
                            {model.changeContract ?
                                <ButtonWidget text={`Claim Reward`} onClick={onClaim} type="img"/> :
                                <ButtonWidget text={`Under Development`} type="img"/>}
                        </SwitchCurrencyButton>
                }
            </ButtonStyle>
        </ChooseCardStyle>
    );
}


const ButtonStyle = styled.div`
  ${StartStyle.ButtonStyle};
  margin-top: 19px;
`
