import styled from "styled-components";
import React from "react";
import {useGetCurrency, useUpDataCurrency} from "../../../state/CurrentCoin/hooks";
import {PidMap} from "../../../server/capita_stake_server";

export const SwitchCurrencyButton: React.FC<{ name: string }> = ({name, children}) => {
    const {currencyList, currency} = useGetCurrency()
    const upData = useUpDataCurrency()
    return (
        <>
            {
                currency.name.toLowerCase() == name.toLowerCase() ? children :
                    <ButtonStyle onClick={async () => {
                        await upData(currencyList[PidMap[name.toLowerCase()]])
                    }
                    }>Switch to {name.toUpperCase()}</ButtonStyle>
            }

        </>
    );
}

const ButtonStyle = styled.div`
  $ {
    StartStyle.ButtonStyle
  };
  background: #AA79E9;
  border-radius: 4px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
`
