import React, {createContext, useEffect, useMemo} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {ChoosePages} from "./choose_page";
import {StakeToMine} from "./stake_to_mine";
import {BorderWidget, NavHeaderWidget, SpacingWidget} from "../../widget/base";
import {StartPage} from "./start_page";
import {WithdrawPage} from "./withdraw_page";
import {ConvertCardInterface} from "../../interface/start_mining";
import {useWeb3} from "../../provider/web3_provider";
import usdt from "../../assets/imgs/usdc.png"
import eth from "../../assets/imgs/eth.png"
import matic from "../../assets/imgs/matic.png"
import {useWait} from "../../utils/wait_fn";
import {HttpServer} from "../../net/http_server";
import {USDT} from "../../nuser/token.config";
import {NetSelectWidget} from "../../widget/base/net_select";

export const StartMiningPage: React.FC = () => {
    const {changeMap, tokenMap} = useWeb3();
    const estimatedThreeApr = useWait<threeApr>(HttpServer.estimatedThreeApr);

    const listModel: { [key: string]: ConvertCardInterface } = useMemo(() => {
        return {
            "eth": {
                bgColor: "#3881e2",
                url: eth,
                poolMCR: "0%",
                nick: "ETH",
                myStaked: "0 nETH",
                cardModel: {},
                changeContract: null,
                tokenContract: null,
                link: "https://app.nsure.network/"
            },
            "matic": {
                bgColor: "#3881e2",
                url: matic,
                poolMCR: "130%",
                nick: "MATIC",
                myStaked: "11.2 nMATIC",
                cardModel: {},
                changeContract: changeMap['ethChangeContract'],
                tokenContract: null,
                rewardUnit: 'Nsure'
            },
            "usdc": {
                bgColor: "#e33fad",
                url: usdt,
                poolMCR: "0%",
                nick: USDT,
                myStaked: "0 nUSDT",
                cardModel: {},
                changeContract: changeMap['usdcChangeContract'],
                tokenContract: tokenMap[USDT],
            },

        };
    }, []);


    const {request, data} = useWait(HttpServer.mcr);
    useEffect(() => {
        request('').then();
        estimatedThreeApr.request('').then()
    }, [])
    return (

        <StartMiningContext.Provider value={{listModel, mcr: data, estimatedThreeApr: estimatedThreeApr.data}}>
            <NetSelectWidget/>

            <div style={{background: "#fff", marginTop: "29px"}}>
                <BorderWidget x={0} y={0} boxShadow={false}>
                    <NavHeaderWidget listNav={[
                        {to: '/start_mining/stake_to_mine/start', text: 'Start Mining'},
                        {to: '/start_mining/stake_to_mine/dashboard', text: 'Dashboard'},
                        {to: '/start_mining/stake_to_mine/withdraw', text: 'Withdraw'},
                    ]}/>
                </BorderWidget>
            </div>


            <SpacingWidget/>

            <Switch>
                <Route path="/start_mining/stake_to_mine/start" exact component={ChoosePages}/>
                <Route path="/start_mining/stake_to_mine/dashboard" exact component={StartPage}/>
                <Route path="/start_mining/stake_to_mine/withdraw" exact component={WithdrawPage}/>
                <Redirect to="/start_mining/stake_to_mine/start"/>
            </Switch>

        </StartMiningContext.Provider>
    );
}

export const StartMiningContext = createContext<StartMiningInterface>({
    listModel: {},
    mcr: {mcr: 0, mcrUsdc:0},
    estimatedThreeApr: {}
});

interface StartMiningInterface {
    listModel: { [key: string]: ConvertCardInterface };
    mcr: { mcr: number, mcrUsdc:number },
    estimatedThreeApr: { [key: string]: threeApr }
}

export type threeApr = { capital: number, averagePremiumReturn: number, policy: number, underwrite: number }
