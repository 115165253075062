import {useStakeBalance} from "../../../server/stake_server";
import {useCallback, useEffect, useState} from "react";
import {useWeb3} from "../../../provider/web3_provider";
import {HttpServer} from "../../../net/http_server";
import {useWallet} from "../../../use_wallet";
import {useGetCurrency} from "../../../state/CurrentCoin/hooks";
import {StartInterface} from "../index";

export const useStakeBalanceHttp = () => {
    const {balanceFn} = useStakeBalance();
    const {account} = useWallet();
    const [res, setRes] = useState<{ [key: string]: StartInterface, }>({});
    const [balanceActivity, setBalance] = useState("0");

    const handleBalance = useCallback(async () => {
        if (account) {
            let balance = await balanceFn();

            let data: any = await HttpServer.stakeBalance(balance,);
            setRes(data);
            setBalance(data["balanceActivity"])
        }
    }, [account, setRes, setBalance]);


    return {handleBalance, stakeBalance: res, balanceActivity}
}
