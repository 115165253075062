import {createReducer} from "@reduxjs/toolkit";
import {upDataCurrency} from "./actions";
import USDCImg from "../../assets/imgs/USDC@2x.png"
import maticImg from "../../assets/imgs/matic@2x.png"


export const CURRENCYKEY = "CURRENCYKEY"

export interface CurrencyState {
    name: string,
    id: number,
    img: string,
    decimals: number,

}

export const currencyList: CurrencyState[] = [
    {
        name: "Matic",
        id: 0,
        img: maticImg,
        decimals: 18,
    },
    {
        name: "USDC",
        id: 1,
        img: USDCImg,
        decimals: 6,
    },
]

export const getCurrencyNameByID = (ID: any = 0) => {
    let name;
    for (let i = 0; i < currencyList.length; i++) {
        let item = currencyList[i];
        if (item.id == ID) {
            name = item.name;
        }
    }
    return name;
}

export const getCurrencyItemByID = (ID: any = 0):CurrencyState => {
    let item = currencyList[0];
    for (let i = 0; i < currencyList.length; i++) {
        let _item = currencyList[i];
        if (_item.id == ID) {
            item = _item;
        }
    }
    return item;
}

function initialState(): {
    currency: CurrencyState,
    currencyList: CurrencyState[]
} {
    let _data: any = localStorage.getItem(CURRENCYKEY);
    let initData = {
        name: "Matic",
        id: 0,
        img: maticImg,
        decimals: 18,

    }
    if (!_data) {
        localStorage.setItem(CURRENCYKEY, JSON.stringify(initData))
    }
    _data = _data ? JSON.parse(_data) : initData;

    return {currency: _data, currencyList: currencyList}

}

export default createReducer(initialState(), builder =>
    builder
        .addCase(upDataCurrency, (state, action) => {
            localStorage.setItem(CURRENCYKEY, JSON.stringify(action.payload))
            state.currency = action.payload;
        })
)
