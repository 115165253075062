import {useWeb3} from "../provider/web3_provider";

import {useSendFn} from "../utils/web3_send";
import {useCallback} from "react";
import {HttpServer} from "../net/http_server";
import {numberToUint256} from "../utils/formatBalance";
import {SignFnface} from "../widget/covers/average/cover_model";
import {useGetCurrency} from "../state/CurrentCoin/hooks";


export const useBuyInsurance = () => {
    const {contrcts, web3} = useWeb3();
    const handleSendFn = useSendFn(contrcts.buyAddress);
    const {currency: {decimals}} = useGetCurrency();

    const facethBack = useCallback(async (data: SignFnface) => {
        let {id, cover, cost, days, currency, v, r, s, deadline} = data;
        let _cost = numberToUint256(cost, decimals).toFixed(0);

        // console.log(id,
        //     numberToUint256(cover, decimals).toFixed(0),
        //     _cost,
        //     days,
        //     v.toString(),
        //     r,
        //     s,
        //     deadline,
        //     currency,);
        let method = contrcts.buyContract.methods.buyInsurance(
            id,
            numberToUint256(cover, decimals).toFixed(0),
            _cost,
            days,
            v.toString(),
            r,
            s,
            deadline,
            currency,
        );

        // @ts-ignore
        let txHash = await handleSendFn(method, currency == 0 ? _cost : '0');
        return txHash.transactionHash
    }, [
        contrcts,
        HttpServer.account,
        web3,
        decimals
    ])
    return [facethBack]
}
