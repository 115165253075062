import buyAbi from './abi/buy.json'
import stakeAbi from './abi/stake.json'
import capitalStakeAbi from './abi/capital_stake.json'
import ClaimPurchaseMint from './abi/ClaimPurchaseMint.json'
import Airdrop from './abi/airdrop.json'
import {Contract} from 'web3-eth-contract';


export interface TokenInterFace {
    address: { [key: string]: string },
    useAddr:string,
    nick: string,
    contract: Contract|any,
    balance?: string,
    decimals:number

}
export interface ChangeInterFace {
    address: string,
    contract: Contract,
}


export const  USDT = 'usdc';
export const NSURE = 'nsure'

export const TokenList: any = {
    usdc: {
        address: {
            1: '',
            137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        },
        nick: 'USDC',
        decimals:6

    },
    nsure: {
        address: {
            1: '',
            137: '0x2D61F243d1a4c16640c3961100f50222679EC89E',
        },
        nick: 'Nsure',
        decimals:18
    },
}


export const ChangeMap:any = {
    // capitalConvertEth
    ethChangeContract: {
        1: '0xa6b658Ce4b1CDb4E7d8f97dFFB549B8688CAFb84',
        137: '0x05F9583926CD311FE960F9Fe8B83bAB3E13AffC1',
    },
    usdcChangeContract: {
        1: '',
        137: '0x96FE3b9B4f99C2bAD5102434128cdE68553b6242',
    },

}

export const ContractMap: any = {
    buyContract: {
        '1': '0x702aff99b08e8891fc70811174701fb7407b4477',
        '137': '0x125bD3B845ef245065f66dc7313024e0E1541948',
        abi: buyAbi.abi
    },
    // underwriting
    stakeContract: {
        1: '0x1a66f065303299d78693f122c800Ab3dEbE9c966',
        137: '0x027B12b4a9af8F919dCBd8473759FAaa216d9923',
        abi: stakeAbi.abi
    },
    capitalStakeContract: {
        1: '0xB98eD9800fCD2982d26Cf0E4a6B53C96bbeff6A6',
        137: '0x3c438CBEC8CF581f6843191228402B9A5801Fb76',
        abi: capitalStakeAbi.abi
    },
    claimPurchaseMintContract: {
        1: '0x200dB99FE5c0f39688853d4c4D8651648d995b26',
        137: '0xBD4822EEE66A42df2175395B7d96225392e27F8b',
        abi: ClaimPurchaseMint.abi
    },
    airDropContract: {
        1: '0x66270E505DDA64345678ab7FD060C1C272573AEE',
        137: '0xe22e5Acede7391E41bcbb2af7b2D12787537470b',
        abi: Airdrop.abi
    },
}
