import {AppConfig} from "../../../config";
import {HttpServer} from "../../../net/http_server";
import {AppleDescriptionInterface} from "../../../widget/covers/submit_claim/apple_description";
import {useV4SignFn} from "../../../utils/sign_v4_fn";
import React, {useCallback, useContext} from "react";
import {ClaimServer} from "../../../net/claim_server";
import App from "../../../App";
import {BackdropContext} from "../../../provider/backdrop_provider";
import {ModalContext} from "../../../provider/model_provider";
import {Backdrop, CircularProgress} from "@material-ui/core";
import {ErrWidget} from "../../../widget/modal/transaction_modal";
import {SuccessWidget} from "../../../widget/covers/submit_claim/success_widget";
import {HttpStatus} from "../../../utils/wait_fn";
import {useWeb3} from "../../../provider/web3_provider";
import {useGetCurrency} from "../../../state/CurrentCoin/hooks";
import {useSignFn} from "../../../utils/sign_v1_fn";


export const useAppleClaimFn = (data: AppleDescriptionInterface) => {
    const signFn = useSignFn();
    const {onPresent, onDismiss} = useContext(ModalContext);
    const {currency} = useGetCurrency()


    const AppleClaim = useCallback(async () => {

        onPresent(<CircularProgress size={100} color="primary"/>)
        try {

            let _msg = appleClaimFn(data, currency.id.toString());
            let singData = await signFn(_msg);
            // let hax = await ipfs.pinJson({
            //     singData
            // });
            let _claimId = await HttpServer.claim(singData, _msg)
            //

            onPresent(<SuccessWidget claimId={_claimId}/>)

        } catch (err) {
            console.log(err)
            if (err.code === 4001) {
                onDismiss()
                return;
            }
            onPresent(<ErrWidget msg={err.msg}/>)

        }


    }, [data, onDismiss,])
    return AppleClaim;
}

export const appleClaimFn = (data: AppleDescriptionInterface, currency: string): string => {

    return JSON.stringify({
        domain: {
            name: 'sign',
            chainId: AppConfig.chainId,
        },
        message: {
            account: HttpServer.account,
            currency,
            id: data._id,
            hash: data.hash,
            description: data.description,
            loss: data.loss,
            credentials: data.credentials,
            orderId: data.orderId,

        },
        primaryType: 'Claim',
        types: {
            EIP712Domain: [
                {name: 'name', type: 'string'},
                {name: 'chainId', type: 'uint256'},
            ],
            Claim: [
                {
                    name: 'account',
                    type: 'address'
                },
                {
                    name: 'currency',
                    type: 'uint256',
                },
                {
                    name: 'id',
                    type: 'string'
                },
                {
                    name: 'hash',
                    type: 'uint256'
                },
                {
                    name: 'loss',
                    type: 'string'
                },
                {
                    name: 'description',
                    type: 'string'
                },
                {
                    name: 'credentials',
                    type: 'string'
                },
                {
                    name: 'orderId',
                    type: 'uint256'
                },
            ],

        }
    });

}
